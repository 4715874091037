/**
 * GNU General Public License v2 or later http://www.gnu.org/licenses/gpl-2.0.html
 * Carlos Longarela https://tabernawp.com/ <carlos at longarela dot eu>
 *
 * @author Carlos Longarela
 *
 * General functions for STEL Order website.
 */

var geoip2: any = undefined;

window.addEventListener( 'DOMContentLoaded', () => {
	get_partner_source();
	get_user_country();

	function get_partner_source() {
		let source = get_url_parameter( "utm_source" );
		if ( source ) {
			let campaign = get_url_parameter( "utm_campaign" );
			set_cookie( "userSource", source );
			set_cookie( "userCampaign", campaign );
		}
	}

	function get_url_parameter( name: string ) {
		let sPageURL 		= decodeURIComponent( window.location.search.substring( 1 ) );
		let sURLVariables 	= sPageURL.split( "&" );
		for ( const element of sURLVariables ) {
			let sParameterName = element.split( "=" );
			if ( sParameterName[0] === name ) {
				return sParameterName[1] === undefined ? true : sParameterName[1];
			}
		}
	}

	function set_cookie( cname: string, cvalue: string | boolean | undefined ) {
		let expiration_date = new Date();
		expiration_date.setFullYear( expiration_date.getFullYear() + 1 );
		let expires 		= "expires=" + expiration_date.toUTCString() + ";";
		document.cookie 	= cname + "=" + cvalue + ";path=/; " + expires;
	}

	function get_cookie( cname : string ) {
		let name 	= cname + "=";
		let ca 		= document.cookie.split( ";" );
		for ( const element of ca ) {
			let elementTrim = element.trim();
			if ( 0 == elementTrim.indexOf( name ) ) {
				return elementTrim.substring( name.length, elementTrim.length );
			}
		}
		return "";
	}

	function get_user_country() {
		if ( "" == get_cookie( "userCountry" ) ) {
			if ( undefined == geoip2 ) {
				var geoip2_script = document.createElement( "script" );
				geoip2_script.setAttribute( "src", "//js.maxmind.com/js/apis/geoip2/v2.1/geoip2.js" );
				document.head.appendChild(geoip2_script);
				var waitForGeoip2 = setInterval(function () {
					if ( undefined == geoip2 ) {
						return;
					}
					end_get_user_country()
					clearInterval(waitForGeoip2);
				}, 100);
			}
		}
	}

	function end_get_user_country() {
		let onSuccess = function( result: any ) {
			set_cookie( "userCountry", result.country.iso_code );
		};

		let onError = function() {
			set_cookie( "userCountry", "ES" );
		};

		try {
			geoip2.country( onSuccess, onError );
		} catch ( e ) {
			onError();
		}
	}

	isPaginaERP();
	function isPaginaERP(){
		if (isPaisES()) {
			if (window.location.href.search(/.com\/en\/erp\/$/) != -1 ||
				window.location.href.search(/.com\/fr\/erp\/$/) != -1 ||
				window.location.href.search(/.com\/mexico\/erp\/$/) != -1 ||
				window.location.href.search(/.com\/chile\/erp\/$/) != -1) {
					location.assign('/erp/');

			}
		} else if (isPaisUS()) {
			if (window.location.href.search(/.com\/erp\/$/) != -1 ||
				window.location.href.search(/.com\/fr\/erp\/$/) != -1 ||
				window.location.href.search(/.com\/mexico\/erp\/$/) != -1 ||
				window.location.href.search(/.com\/chile\/erp\/$/) != -1) {
					location.assign('/en/erp/');
			}
		} else if (isPaisFR()) {
			if (window.location.href.search(/.com\/erp\/$/) != -1 ||
				window.location.href.search(/.com\/en\/erp\/$/) != -1 ||
				window.location.href.search(/.com\/mexico\/erp\/$/) != -1 ||
				window.location.href.search(/.com\/chile\/erp\/$/) != -1) {
					location.assign('/fr/erp/');
			}
		} else if (isPaisCL()) {
			if (window.location.href.search(/.com\/erp\/$/) != -1 ||
				window.location.href.search(/.com\/en\/erp\/$/) != -1 ||
				window.location.href.search(/.com\/fr\/erp\/$/) != -1 ||
				window.location.href.search(/.com\/mexico\/erp\/$/) != -1) {
					location.assign('/chile/erp/');
			}
		} else if (isPaisMX()) {
			if (window.location.href.search(/.com\/erp\/$/) != -1 ||
				window.location.href.search(/.com\/en\/erp\/$/) != -1 ||
				window.location.href.search(/.com\/fr\/erp\/$/) != -1 ||
				window.location.href.search(/.com\/chile\/erp\/$/) != -1) {
					location.assign('/mexico/erp/');
			}
		}
	}

	isPaginaFacturacion();
	function isPaginaFacturacion(){
		if (isPaisES()) {
			if (window.location.href.search(/.com\/en\/invoicing-software\/$/) != -1 ||
				window.location.href.search(/.com\/fr\/logiciel-de-facturation\/$/) != -1 ||
				window.location.href.search(/.com\/mexico\/sistema-de-facturacion\/$/) != -1 ||
				window.location.href.search(/.com\/chile\/sistema-facturacion\/$/) != -1) {
					location.assign('/programa-de-facturacion/');

			}
		} else if (isPaisUS()) {
			if (window.location.href.search(/.com\/programa-de-facturacion\/$/) != -1 ||
				window.location.href.search(/.com\/fr\/logiciel-de-facturation\/$/) != -1 ||
				window.location.href.search(/.com\/mexico\/sistema-de-facturacion\/$/) != -1 ||
				window.location.href.search(/.com\/chile\/sistema-facturacion\/$/) != -1) {
					location.assign('/en/invoicing-software/');
			}
		} else if (isPaisFR()) {
			if (window.location.href.search(/.com\/programa-de-facturacion\/$/) != -1 ||
				window.location.href.search(/.com\/en\/invoicing-software\/$/) != -1 ||
				window.location.href.search(/.com\/mexico\/sistema-de-facturacion\/$/) != -1 ||
				window.location.href.search(/.com\/chile\/sistema-facturacion\/$/) != -1) {
					location.assign('/fr/logiciel-de-facturation/');
			}
		} else if (isPaisCL()) {
			if (window.location.href.search(/.com\/programa-de-facturacion\/$/) != -1 ||
				window.location.href.search(/.com\/en\/invoicing-software\/$/) != -1 ||
				window.location.href.search(/.com\/fr\/logiciel-de-facturation\/$/) != -1 ||
				window.location.href.search(/.com\/mexico\/sistema-de-facturacion\/$/) != -1) {
					location.assign('/chile/sistema-facturacion/');
			}
		} else if (isPaisMX()) {
			if (window.location.href.search(/.com\/programa-de-facturacion\/$/) != -1 ||
				window.location.href.search(/.com\/en\/invoicing-software\/$/) != -1 ||
				window.location.href.search(/.com\/fr\/logiciel-de-facturation\/$/) != -1 ||
				window.location.href.search(/.com\/chile\/sistema-facturacion\/$/) != -1) {
					location.assign('/mexico/sistema-de-facturacion/');
			}
		}
	}

	isPaginaDemo();
	function isPaginaDemo(){
		if (isPaisES()) {
			if (window.location.href.search(/.com\/en\/request-an-online-demo\/$/) != -1 ||
				window.location.href.search(/.com\/fr\/soliciter-une-demonstration\/$/) != -1 ||
				window.location.href.search(/.com\/mexico\/solicitar-demostracion\/$/) != -1 ||
				window.location.href.search(/.com\/chile\/solicitar-demostracion\/$/) != -1) {
					location.assign('/solicitar-demostracion/');

			}
		} else if (isPaisUS()) {
			if (window.location.href.search(/.com\/solicitar-demostracion\/$/) != -1 ||
				window.location.href.search(/.com\/fr\/soliciter-une-demonstration\/$/) != -1 ||
				window.location.href.search(/.com\/mexico\/solicitar-demostracion\/$/) != -1 ||
				window.location.href.search(/.com\/chile\/solicitar-demostracion\/$/) != -1) {
					location.assign('/en/request-an-online-demo/');
			}
		} else if (isPaisFR()) {
			if (window.location.href.search(/.com\/solicitar-demostracion\/$/) != -1 ||
				window.location.href.search(/.com\/en\/request-an-online-demo\/$/) != -1 ||
				window.location.href.search(/.com\/mexico\/solicitar-demostracion\/$/) != -1 ||
				window.location.href.search(/.com\/chile\/solicitar-demostracion\/$/) != -1) {
					location.assign('/fr/soliciter-une-demonstration/');
			}
		} else if (isPaisCL()) {
			if (window.location.href.search(/.com\/solicitar-demostracion\/$/) != -1 ||
				window.location.href.search(/.com\/en\/request-an-online-demo\/$/) != -1 ||
				window.location.href.search(/.com\/fr\/soliciter-une-demonstration\/$/) != -1 ||
				window.location.href.search(/.com\/mexico\/solicitar-demostracion\/$/) != -1) {
					location.assign('/chile/solicitar-demostracion/');
			}
		} else if (isPaisMX()) {
			if (window.location.href.search(/.com\/solicitar-demostracion\/$/) != -1 ||
				window.location.href.search(/.com\/en\/request-an-online-demo\/$/) != -1 ||
				window.location.href.search(/.com\/fr\/soliciter-une-demonstration\/$/) != -1 ||
				window.location.href.search(/.com\/chile\/solicitar-demostracion\/$/) != -1) {
					location.assign('/mexico/solicitar-demostracion/');
			}
		} else if (isPaisLatam()) {
			if (window.location.href.search(/.com\/solicitar-demostracion\/$/) != -1 ||
				window.location.href.search(/.com\/en\/request-an-online-demo\/$/) != -1 ||
				window.location.href.search(/.com\/fr\/soliciter-une-demonstration\/$/) != -1 ||
				window.location.href.search(/.com\/chile\/solicitar-demostracion\/$/) != -1) {
					location.assign('/mexico/solicitar-demostracion/');
			}
		}
	}


	isPaginaProductosCompuestos();
	function isPaginaProductosCompuestos(){
		if (isPaisES()) {
			if ( window.location.href.search(/\/en\/erp\/bundled-products\/$/) != -1 ||
            window.location.href.search(/\/fr\/erp\/produits-composes\/$/) != -1 ||
            window.location.href.search(/\/chile\/erp\/productos-compuestos\/$/) != -1 ||
            window.location.href.search(/\/mexico\/erp\/productos-compuestos\/$/) != -1 ) {
					location.assign('/erp\/productos-compuestos/');

			}
		} else if (isPaisUS()) { // Verifica si el país es Estados Unidos
			if (
				window.location.href.search(/\/erp\/productos-compuestos\/$/) != -1 ||
				window.location.href.search(/\/fr\/erp\/produits-composes\/$/) != -1 ||
				window.location.href.search(/\/chile\/erp\/productos-compuestos\/$/) != -1 ||
				window.location.href.search(/\/mexico\/erp\/productos-compuestos\/$/) != -1
			) {
				// Redirige a la versión en inglés de la página de productos compuestos
				location.assign('/en/erp/bundled-products/');
			}
		} else if (isPaisFR()) {
			if (
				window.location.href.search(/\/erp\/productos-compuestos\/$/) != -1 ||
            	window.location.href.search(/\/en\/erp\/bundled-products\/$/) != -1 ||
         		window.location.href.search(/\/chile\/erp\/productos-compuestos\/$/) != -1 ||
            	window.location.href.search(/\/mexico\/erp\/productos-compuestos\/$/) != -1

			) {
				// Redirige a la versión en francés de la página de productos compuestos para Francia
				location.assign('/fr/erp/produits-composes/');
			}
		}

	}

	isPaginaPrecio();
	function isPaginaPrecio() {
		if (isPaisUS()) {
			if (window.location.href.search(/.com\/precio\/$/) != -1 ||
				window.location.href.search(/.com\/fr\/prix\/$/) != -1 ||
				window.location.href.search(/.com\/mexico\/precio\/$/) != -1 ||
				window.location.href.search(/.com\/chile\/precio\/$/) != -1 ||
				window.location.href.search(/.com\/precio\/latam\/$/) != -1) {
					location.assign('/en/pricing/');

			}
		} else if (isPaisMX()) {
			if (window.location.href.search(/.com\/precio\/$/) != -1 ||
				window.location.href.search(/.com\/en\/pricing\/$/) != -1 ||
				window.location.href.search(/.com\/en\/pricing\/us\/$/) != -1 ||
				window.location.href.search(/.com\/fr\/prix\/$/) != -1 ||
				window.location.href.search(/.com\/chile\/precio\/$/) != -1 ||
				window.location.href.search(/.com\/precio\/latam\/$/) != -1) {
					location.assign('/mexico/precio/');
			}
		} else if (isPaisCL()) {
			if (window.location.href.search(/.com\/precio\/$/) != -1 ||
				window.location.href.search(/.com\/en\/pricing\/$/) != -1 ||
				window.location.href.search(/.com\/en\/pricing\/us\/$/) != -1 ||
				window.location.href.search(/.com\/fr\/prix\/$/) != -1 ||
				window.location.href.search(/.com\/mexico\/precio\/$/) != -1 ||
				window.location.href.search(/.com\/precio\/latam\/$/) != -1) {
					location.assign('/chile/precio/');
			}
		} else if (isPaisLatam()) {
			if (window.location.href.search(/.com\/precio\/$/) != -1 ||
				window.location.href.search(/.com\/en\/pricing\/$/) != -1 ||
				window.location.href.search(/.com\/en\/pricing\/us\/$/) != -1 ||
				window.location.href.search(/.com\/fr\/prix\/$/) != -1 ||
				window.location.href.search(/.com\/mexico\/precio\/$/) != -1 ||
				window.location.href.search(/.com\/chile\/precio\/$/) != -1) {
					location.assign('/precio/latam/');
			}
		}
	}

	isPaginaTempo();
	function isPaginaTempo(){
		if (isPaisLatam()) {
			if (window.location.href.search(/.com\/tempo\/$/) != -1 ||
				window.location.href.search(/.com\/en\/tempo\/$/) != -1 ||
				window.location.href.search(/.com\/fr\/tempo\/$/) != -1 ||
				window.location.href.search(/.com\/mexico\/tempo\/$/) != -1 ||
				window.location.href.search(/.com\/chile\/tempo\/$/) != -1) {
					location.assign('/tempo/latam/');

			}
		}
	}

	isPaginaFacturacionLATAM();
	function isPaginaFacturacionLATAM(){
		if (isPaisLatam()) {
			if (window.location.href.search(/.com\/programa-de-facturacion\/$/) != -1 ||
				window.location.href.search(/.com\/en\/invoicing-software\/$/) != -1 ||
				window.location.href.search(/.com\/fr\/logiciel-de-facturation\/$/) != -1 ||
				window.location.href.search(/.com\/mexico\/sistema-de-facturacion\/$/) != -1 ||
				window.location.href.search(/.com\/chile\/sistema-facturacion\/$/) != -1) {
					location.assign('/programa-de-facturacion/latam/');

			}
		}
	}

	isPaginaHOMEUS();
	function isPaginaHOMEUS(){
		if (isPaisUS()) {
			if (window.location.href.search(/.com\/$/) != -1 ||
				window.location.href.search(/.com\/fr\/$/) != -1 ||
				window.location.href.search(/.com\/mexico\/$/) != -1 ||
				window.location.href.search(/.com\/chile\/$/) != -1) {
					location.assign('/en/');

			}
		}
	}



	function isPaisES() {
		if (get_cookie("userCountry") == 'ES') {
			return true;
		}
		return false;
	}

	function isPaisFR() {
		if (get_cookie("userCountry") == 'FR') {
			return true;
		}
		return false;
	}

	function isPaisUS() {
		if (get_cookie("userCountry") == 'US' ||
			get_cookie("userCountry") == 'PR') {
			return true;
		}
		return false;
	}

	function isPaisMX() {
		if (get_cookie("userCountry") == 'MX') {
			return true;
		}
		return false;
	}

	function isPaisCL() {
		if (get_cookie("userCountry") == 'CL') {
			return true;
		}
		return false;
	}

	function isPaisLatam() {
		if (get_cookie("userCountry") == 'BZ' ||
			get_cookie("userCountry") == 'CR' ||
			get_cookie("userCountry") == 'SV' ||
			get_cookie("userCountry") == 'GT' ||
			get_cookie("userCountry") == 'HN' ||
			get_cookie("userCountry") == 'NI' ||
			get_cookie("userCountry") == 'PA' ||
			get_cookie("userCountry") == 'AR' ||
			get_cookie("userCountry") == 'BO' ||
			get_cookie("userCountry") == 'BR' ||
			get_cookie("userCountry") == 'CO' ||
			get_cookie("userCountry") == 'EC' ||
			get_cookie("userCountry") == 'GY' ||
			get_cookie("userCountry") == 'PY' ||
			get_cookie("userCountry") == 'PE' ||
			get_cookie("userCountry") == 'SR' ||
			get_cookie("userCountry") == 'UY' ||
			get_cookie("userCountry") == 'VE' ||
			get_cookie("userCountry") == 'DO') {
			return true;
		}
		return false;
	}

	const btns_init_session    	= document.getElementsByClassName( 'cl-init-session' );
	const btns_tempo_session    = document.getElementsByClassName( 'al-tempo-session' );
	const btns_try_me    		= document.getElementsByClassName( 'cl-btn-try' );
	const btns_try_me_lite_old 	= document.getElementsByClassName( 'btn-try-lite' );
	const btns_try_me_free    	= document.getElementsByClassName( 'flbp-try-free' );
	const btns_try_me_lite    	= document.getElementsByClassName( 'flbp-try-lite' );
	const btns_try_me_business 	= document.getElementsByClassName( 'flbp-try-business' );
	const btns_try_me_pro    	= document.getElementsByClassName( 'flbp-try-pro' );
	const hrefs_try_me   		= document.getElementsByClassName( 'cl-href-try' );
	const youtube_videos 		= document.getElementsByClassName( 'cl-yt-video' );
	const tab_titles     		= [
		document.getElementsByClassName( 'cl-tab-title' ),
		document.getElementsByClassName( 'cl-tab-title-2' ),
		document.getElementsByClassName( 'cl-tab-title-3' ),
		document.getElementsByClassName( 'cl-tab-title-4' ),
	];
	const partner_btn                                = document.getElementsByClassName( 'cl-btn-partner' );
	const cl_details: NodeListOf<HTMLDetailsElement> = document.querySelectorAll( '.cl-details details' );
	const mobile_menu_item                           = document.querySelectorAll( '#mobile-menu > ul > li' );

	let selected_title_block = [
		'cl-title-1',
		'cl-title-2-1',
		'cl-title-3-1',
		'cl-title-4-1',
	];

	let selected_block = [
		'cl-txt-1',
		'cl-txt-2-1',
		'cl-txt-3-1',
		'cl-txt-4-1',
	];

	function goto_initsession_url() {
		let user_source_link   = get_cookie( "userSource" ) ? get_cookie( "userSource" ) : 'WEB-' + window.location.pathname;
		let user_campaign_link = get_cookie( "userCampaign" ) ? get_cookie( "userCampaign" ) : 'Ninguna';
		let user_country       = get_cookie( "userCountry") ?  get_cookie( "userCountry") : navigator.language.split( '-' )[0].toUpperCase();
		let register_email     = '';

		window.open( 'https://www.stelorder.com/app/#userSource=' + user_source_link + '#userCampaign=' + user_campaign_link + '#userCountry=' + user_country + '#registerEmail=' + register_email );
	}

	function goto_tryme_url() {
		let stel_form          = 'registro';
		let user_source_link   = get_cookie( "userSource" ) ? get_cookie( "userSource" ) : 'WEB-' + window.location.pathname;
		let user_campaign_link = get_cookie( "userCampaign" ) ? get_cookie( "userCampaign" ) : 'Ninguna';
		let user_country       = get_cookie( "userCountry") ?  get_cookie( "userCountry") : navigator.language.split( '-' )[0].toUpperCase();
		let register_email     = '';

		window.open( 'https://www.stelorder.com/app/#form=' + stel_form + '#userSource=' + user_source_link + '#userCampaign=' + user_campaign_link + '#userCountry=' + user_country + '#registerEmail=' + register_email );
	}

	function goto_tryme_url_lite_old() {
		let stel_form          = 'registro';
		let user_source_link   = get_cookie( "userSource" ) ? get_cookie( "userSource" ) : 'LITE-' + window.location.pathname;
		let user_campaign_link = get_cookie( "userCampaign" ) ? get_cookie( "userCampaign" ) : 'Ninguna';
		let user_country       = get_cookie( "userCountry") ?  get_cookie( "userCountry") : navigator.language.split( '-' )[0].toUpperCase();
		let register_email     = '';

		window.open( 'https://www.stelorder.com/app/#form=' + stel_form + '#userSource=' + user_source_link + '#userCampaign=' + user_campaign_link + '#userCountry=' + user_country + '#registerEmail=' + register_email );
	}

	function goto_partner_url() {
		let user_source_link   = get_cookie( "userSource" ) ? get_cookie( "userSource" ) : 'WEB-' + window.location.pathname;
		let user_campaign_link = get_cookie( "userCampaign" ) ? get_cookie( "userCampaign" ) : 'Ninguna';
		let user_country       = get_cookie( "userCountry") ?  get_cookie( "userCountry") : navigator.language.split( '-' )[0].toUpperCase();

		window.open( 'https://www.stelorder.com/app/#form=registroPartner#userSource=' + user_source_link + '#userCampaign=' + user_campaign_link + '#userCountry=' + user_country );
	}

// FUNCIONAMIENTO DE BOTONES - PLANES FLBP: FREE - LITE - BUSINESS - PRO

	function goto_tryme_url_free() {
		let stel_form          		= 'registro';
		let tipo_plan_suscripcion 	= 'FREE';
		let user_source_link   		= get_cookie( "userSource" ) ? get_cookie( "userSource" ) : 'WEB-' + window.location.pathname;
		let user_campaign_link 		= get_cookie( "userCampaign" ) ? get_cookie( "userCampaign" ) : 'Ninguna';
		let user_country       		= get_cookie( "userCountry") ?  get_cookie( "userCountry") : navigator.language.split( '-' )[0].toUpperCase();
		let register_email     		= '';

		window.open( 'https://www.stelorder.com/app/#form=' + stel_form + '#tipoPlanSuscripcionGeneral=' + tipo_plan_suscripcion  + '#userSource=' + user_source_link + '#userCampaign=' + user_campaign_link + '#userCountry=' + user_country + '#registerEmail=' + register_email );
	}

	for ( let btn of btns_try_me_free ) {
		btn.addEventListener( 'click', function() {
			goto_tryme_url_free();
		}, false );
	}

	function goto_tryme_url_lite() {
		let stel_form          		= 'registro';
		let tipo_plan_suscripcion 	= 'LITE';
		let user_source_link   		= get_cookie( "userSource" ) ? get_cookie( "userSource" ) : 'WEB-' + window.location.pathname;
		let user_campaign_link 		= get_cookie( "userCampaign" ) ? get_cookie( "userCampaign" ) : 'Ninguna';
		let user_country       		= get_cookie( "userCountry") ?  get_cookie( "userCountry") : navigator.language.split( '-' )[0].toUpperCase();
		let register_email     		= '';

		window.open( 'https://www.stelorder.com/app/#form=' + stel_form + '#tipoPlanSuscripcionGeneral=' + tipo_plan_suscripcion  + '#userSource=' + user_source_link + '#userCampaign=' + user_campaign_link + '#userCountry=' + user_country + '#registerEmail=' + register_email );
	}

	for ( let btn of btns_try_me_lite ) {
		btn.addEventListener( 'click', function() {
			goto_tryme_url_lite();
		}, false );
	}

	function goto_tryme_url_business() {
		let stel_form          		= 'registro';
		let tipo_plan_suscripcion 	= 'BUSINESS';
		let user_source_link   		= get_cookie( "userSource" ) ? get_cookie( "userSource" ) : 'WEB-' + window.location.pathname;
		let user_campaign_link 		= get_cookie( "userCampaign" ) ? get_cookie( "userCampaign" ) : 'Ninguna';
		let user_country       		= get_cookie( "userCountry") ?  get_cookie( "userCountry") : navigator.language.split( '-' )[0].toUpperCase();
		let register_email     		= '';

		window.open( 'https://www.stelorder.com/app/#form=' + stel_form + '#tipoPlanSuscripcionGeneral=' + tipo_plan_suscripcion  + '#userSource=' + user_source_link + '#userCampaign=' + user_campaign_link + '#userCountry=' + user_country + '#registerEmail=' + register_email );
	}

	for ( let btn of btns_try_me_business ) {
		btn.addEventListener( 'click', function() {
			goto_tryme_url_business();
		}, false );
	}

	function goto_tryme_url_pro() {
		let stel_form          		= 'registro';
		let tipo_plan_suscripcion 	= 'PRO';
		let user_source_link   		= get_cookie( "userSource" ) ? get_cookie( "userSource" ) : 'WEB-' + window.location.pathname;
		let user_campaign_link 		= get_cookie( "userCampaign" ) ? get_cookie( "userCampaign" ) : 'Ninguna';
		let user_country       		= get_cookie( "userCountry") ?  get_cookie( "userCountry") : navigator.language.split( '-' )[0].toUpperCase();
		let register_email     		= '';

		window.open( 'https://www.stelorder.com/app/#form=' + stel_form + '#tipoPlanSuscripcionGeneral=' + tipo_plan_suscripcion  + '#userSource=' + user_source_link + '#userCampaign=' + user_campaign_link + '#userCountry=' + user_country + '#registerEmail=' + register_email );
	}

	for ( let btn of btns_try_me_pro ) {
		btn.addEventListener( 'click', function() {
			goto_tryme_url_pro();
		}, false );
	}

/// FIN FLBP


	function mobile_menu_toggle( item: Element ) {
		let open_menu_class     = 'sfHover';
		let open_sub_menu_class = 'toggled-on';
		let sub_menu            = item.querySelector( 'ul' );

		if ( sub_menu ) {
			if ( item.classList.contains( open_menu_class ) ) {
				sub_menu.classList.remove( open_sub_menu_class );
				item.classList.remove( open_menu_class );
			} else {
				sub_menu.classList.add( open_sub_menu_class );
				item.classList.add( open_menu_class );
			}
		}
	}

	for ( let ptr of partner_btn ) {
		ptr.addEventListener( 'click', function() {
			goto_partner_url();
		}, false );
	}

	for ( let btn of btns_init_session ) {
		btn.addEventListener( 'click', function() {
			goto_initsession_url();
		}, false );
	}

	for ( let btn of btns_tempo_session ) {
		btn.addEventListener( 'click', function() {
			goto_tryme_url();
		}, false );
	}

	for ( let btn of btns_try_me ) {
		btn.addEventListener( 'click', function() {
			goto_tryme_url();
		}, false );
	}

	for ( let btn of btns_try_me_lite_old ) {
		btn.addEventListener( 'click', function() {
			goto_tryme_url_lite_old();
		}, false );
	}

	for ( let href of hrefs_try_me ) {
		href.addEventListener( 'click', function() {
			goto_tryme_url();
		}, false );
	}

	for ( let item of mobile_menu_item ) {
		item.addEventListener( 'click', function() {
			mobile_menu_toggle( item );
		}, false );
	}

	function change_img2video( video: Element ) {
		let video_url                   = video.getAttribute( 'data-video-url' );
		let video_width : string | null = '790';
		let video_height: string | null = '430';

		if ( video.hasAttribute( 'data-video-width' ) ) {
			video_width = video.getAttribute( 'data-video-width' );
		}

		if ( video.hasAttribute( 'data-video-height' ) ) {
			video_height = video.getAttribute( 'data-video-height' );
		}

		video_url += '?w=' + video_width + '&h=' + video_height + '&autoplay=1&playsinline=1&modestbranding=1'
		let youtube_iframe = '<iframe class="cl-youtube-iframe" width="' + video_width + '" height="' + video_height + '" src="' + video_url + '" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';

		// Delete all content inside div.cl-yt-video.
		while ( video.firstChild ) {
			video.removeChild( video.firstChild );
		}

		// Add new class to the container to make iframe responsive.
		video.classList.add( 'cl-youtube-loaded' );

		// Add YouTube iframe indide div.cl-yt-video.
		video.insertAdjacentHTML( 'afterbegin', youtube_iframe );

		//console.log( 'Video Youtube en: ' + video_url );
	}

	function change_tab_content( title: Element, index: number ) {
		let title_url             = title.getAttribute( 'href' );
		const current_title_block = document.getElementById( selected_title_block[ index ] );
		const current_block       = document.getElementById( selected_block[ index ] );
		const current_title_id    = title.getAttribute( 'id' );

		title.classList.add( 'cl-tab-active' );

		if ( current_title_block ) {
			current_title_block.classList.remove( 'cl-tab-active' );
		}

		if ( current_block ) {
			current_block.classList.add( 'cl-content-hidden' );
		}

		if ( current_title_id ) {
			selected_title_block[ index ] = current_title_id;
		}

		if ( title_url ) {
			title_url               = title_url.substring(1);
			selected_block[ index ] = title_url;

			const title_block = document.getElementById( title_url );

			if ( title_block ) {
				title_block.classList.remove( 'cl-content-hidden' );
				title_block.scrollIntoView( { block: 'nearest', behavior: 'smooth' } );
			}
		}
	}

	function change_details_image( details_element: Element ) {
		let image_url     = details_element.getAttribute( 'data-image-url' );
		let image_id_dest = details_element.getAttribute( 'data-image-id-dest' );

		if ( image_url && image_id_dest ) {
			let image_dest = document.querySelector( '#' + image_id_dest + ' img' );
			if ( image_dest ) {
				image_dest.setAttribute( 'src', image_url );
				image_dest.removeAttribute( 'srcset' );
			}
		}
	}

	for ( let video of youtube_videos ) {
		video.addEventListener( 'click', function() {
			change_img2video( video );
		}, false );
	}

	for ( let title_0 of tab_titles[ 0 ] ) {
		title_0.addEventListener( 'click', function( event ) {
			event.preventDefault();
			change_details_image( title_0 );
			change_tab_content( title_0, 0 );
		}, false );
	}

	for ( let title_1 of tab_titles[ 1 ] ) {
		title_1.addEventListener( 'click', function( event ) {
			event.preventDefault();
			change_details_image( title_1 );
			change_tab_content( title_1, 1 );
		}, false );
	}

	for ( let title_2 of tab_titles[ 2 ] ) {
		title_2.addEventListener( 'click', function( event ) {
			event.preventDefault();
			change_details_image( title_2 );
			change_tab_content( title_2, 2 );
		}, false );
	}

	for ( let title_3 of tab_titles[ 3 ] ) {
		title_3.addEventListener( 'click', function( event ) {
			event.preventDefault();
			change_details_image( title_3 );
			change_tab_content( title_3, 3 );
		}, false );
	}

	cl_details.forEach( elem => {
		elem.addEventListener( 'toggle', function( this: HTMLDetailsElement ) {
			if ( this.open ) {
				change_details_image( this );

				cl_details.forEach( elem => {
					if ( elem !== this && elem.open ) {
						elem.open = false;
					}
				} );
				elem.scrollIntoView( { block: 'center', behavior: 'smooth' } );
			}
		} );
	} );

} );

function isIOS() {
	var ua = navigator.userAgent.toLowerCase();
	var iphone = ua.indexOf("iphone") > -1;
	if (iphone) {
		return true;
	} else {
		return false;
	}
}

function isAndroid() {
	var ua = navigator.userAgent.toLowerCase();
	var isAndroid = ua.indexOf("android") > -1;
	if (isAndroid) {
		return true;
	} else {
		return false;
	}
}

function redireccionMobileAPP() {
	if (window.location.href.indexOf('/aplicaciones-moviles/') != -1) {
		var language = 'es';
		if (window.location.href.indexOf("/fr/") != -1) {
			language = 'fr';
		} else if (window.location.href.indexOf("/en/") != -1) {
			language = 'en';
		}

		if (isIOS()) {
			if (language == 'es_419') {
				language = 'mx';
			}
			if (language == 'en') {
				window.location.href = 'https://itunes.apple.com/es/app/stel-order-pro-erp-facturas/id6444916227?l=' + language + '&ls=1&mt=8';
			} else {
				window.location.href = 'https://itunes.apple.com/' + language + '/app/stel-order-pro-erp-facturas/id6444916227?l=' + language + '&ls=1&mt=8';
			}
		} else if (isAndroid()) {
			window.location.href = 'https://play.google.com/store/apps/details?id=com.stel.order&hl=' + language;
		} else {
			if (window.location.href.indexOf("/fr/") != -1) {
				window.location.href = '/fr/';
			} else if (window.location.href.indexOf("/en/") != -1) {
				window.location.href = '/en/';
			} else if (window.location.href.indexOf("/mexico/") != -1) {
				window.location.href = '/mexico/';
			} else {
				window.location.href = '/';
			}
		}
	}
}
